import { Box } from '@mui/joy';
import { Logo } from './Logo';

export const LogoCentered = ({ image, account_organisation_name }) => {
  return (
    <Box
      mb={5}
      display={'flex'}
      justifyContent={'center'}
    >
      <Logo
        image={image}
        account_organisation_name={account_organisation_name}
      />
    </Box>
  );
};
